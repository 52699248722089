/* eslint-disable no-undef */
import { httpGet, httpPost } from '../http';

export const GetClientToken = () =>
    httpGet('/v3/orders/client-token', { authType: 'Bearer' });

export const CreateCreditCard = (
    requestBody = { key, nonce_key, card_type, number },
    device_data
) => {
    return httpPost('/v3/users/card', requestBody, {
        authType: 'Bearer',
        additionalHeaders: { 'X-Braintree-Device-Data': device_data },
    });
};

export const CreateCreditCardOmise = (requestBody = { token }) => {
    return httpPost('/v5/payment/method/omise', requestBody, {
        authType: 'Bearer',
    });
};

export const CreateAdyenPaymentMethod = (requestBody) => {
    return httpPost('/v5/payment/method/adyen', requestBody, {
        authType: 'Bearer',
    });
};

export const GetPaymentTime = () =>
    httpGet('/v5/payment/time', { authType: 'Bearer' });

export const StoreStripeToken = (requestBody) => {
    return httpPost('/v5/payment/method/stripe', requestBody, {
        authType: 'Bearer',
    });
};

export const ConfirmSetupIntent = () => {
    return httpPost('/v5/payment/method/stripe/setup-intent', null, {
        authType: 'Bearer',
    });
};

export const CreateSelectSubscription = (planId) => {
    return httpPost(
        `/v1/select/subscribe`,
        { plan_id: planId },
        { authType: 'Bearer' }
    );
};

export const SendStripeReceipt = (requestBody) => {
    return httpPost('/v5/payment/stripe/send_receipt', requestBody, {
        authType: 'Bearer',
    });
};
