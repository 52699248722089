/* eslint-disable max-statements */
import { observable, computed, action, when } from 'mobx';
import Bacon from 'baconjs';
import { currency } from '../services';
import { services } from '../services/index';

import { RESOURCE } from '../services/types';
import Notifiable from './services/notifiable';
import CartServices from './services/cartServices';

import { SMALL_SCREEN_MEDIA } from '../services/constants';
import Cookies from 'js-cookie';
import qs from 'query-string';
import { clearReferralItemsFromLocalStorage } from '../services/referrals';
import { UserAddressSettings } from '../services/api/userAddressServices';
import { UserCart } from '../services/api/userCartServices';
import cartStore from './cartStore';
import deliveryAddressStore from './deliveryAddressStore';
import cartViewStore from './cartViewStore';
import menuListStore from './menuListStore';
import { removeActiveTimeslotObj } from '../services/timeslot';
import { removeRewardToApply } from '../services/rewards';
import { getCardTypeText } from '../services/paymentProviders';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(services.getParam('stripeAPIKey'));

const { clevertap } = window;
const INTERVAl = 10 * 1000;

class MenuCheckoutStore extends Notifiable {
    PENDING = 'Pending';
    COMPLETE = 'Complete';
    CANCELLED = 'Cancelled';
    FAILED = 'Failed';

    CHECKOUT_VIEW = 'CheckoutView';
    EMPTY_VIEW = 'EmptyView';
    CONFIRM_VIEW = 'ConfirmView';
    LOADING_VIEW = 'LoadingView';
    PAYMENT_IN_PROGRESS_VIEW = 'PaymentInProgressView';

    @observable view = 'CheckoutView';
    @observable isBusy = true;
    @observable message = '';
    @observable discountCode = '';
    @observable hasUserUpdatedProfile = false;
    @observable orderId = '';
    @observable selectedTimeslots = [];
    @observable.ref status = RESOURCE.Initial;
    @observable.ref user = null;
    @observable.ref paymentMethod = null;
    @observable.ref orderSummary = null;
    @observable.ref finalOrderSummary = null;
    @observable selectedAddress = null;
    @observable openBoostPopup = false;
    @observable boostDeepLink = '';
    @observable boostOrderId = '';
    @observable proceed = null;
    @observable isEditing = false;
    @observable preferredAddress = {};
    @observable clickedGoToCheckout = false;
    @observable clientSecret = '';
    @observable paymentIntentId = null;

    cart() {
        return UserCart.getCart() || [];
    }

    handleNextAction() {
        const stripe = useStripe();
    }

    userChosenTimeslots = {};
    userChosenAddresses = {}; // an object where each key is [date][mealTime][addressId]
    preferredAddressDinner = null;
    preferredAddressLunch = null;
    preferredAddressWeekend = null;

    onUserLoaded$ = new Bacon.Bus();
    onPaymentMethodsLoaded$ = new Bacon.Bus();
    onOrderSummaryLoaded$ = new Bacon.Bus();
    onPlacingOrderFailed$ = new Bacon.Bus();

    @computed get rewardSummary() {
        return this.orderSummary && this.orderSummary.reward_summary;
    }

    @computed get itemsInCart() {
        let itemsInCart = [];
        this.cart().forEach((dateOrder) => {
            itemsInCart = itemsInCart.concat.apply([], dateOrder.menu_item);
        });

        return itemsInCart;
    }

    constructor() {
        super();

        this.setUpNotification();

        Bacon.onValues(this.onUserLoaded$, this.onPaymentMethodsLoaded$, ::this.getOrderSummary);

        const savedSelectedAddress = UserAddressSettings.getCurrentAddress();

        if (savedSelectedAddress) {
            this.selectedAddress = savedSelectedAddress;
        }

        when(
            () => this.hasUserUpdatedProfile === true,
            () => {
                services.api.GetUserWithMemoizer.runFresh().then((user) => {
                    this.user = user;
                });
            }
        );
    }

    handleGoToCheckout() {
        this.clickedGoToCheckout = true;

        when(
            () => cartStore.preliminaryOrderSummary,
            () => {
                const incompleteAddress =
                    deliveryAddressStore.selectedAddress && deliveryAddressStore.selectedAddress.address_incomplete;
                if (incompleteAddress) {
                    deliveryAddressStore.editAddress(deliveryAddressStore.selectedAddress.id);
                } else {
                    cartViewStore.setView(cartViewStore.STEP_2);
                }
            }
        );
    }

    startPolling() {
        this.loadStatus();

        this.interval = window.setInterval(::this.loadStatus, INTERVAl);
    }

    @action loadStatus() {
        this.isBusy = true;

        services.api
            .CheckPurchaseStatus(this.orderId)
            .then((responseData) => {
                if (responseData['transaction_status'] === this.COMPLETE) {
                    window.clearInterval(this.interval);
                    this.finalOrderSummary = responseData;
                    this.sendTrackingEvents();
                    this.status = RESOURCE.Success(responseData);
                    services.api.UserCart.clear();
                    this.view = this.CONFIRM_VIEW;
                } else if (responseData['transaction_status'] === this.PENDING) {
                    this.isBusy = true;
                } else {
                    throw responseData;
                }
            })
            .catch((responseData) => {
                if (responseData.status === 404) {
                    this.status = RESOURCE.Failure(`Order number ${this.orderId} is invalid.`, responseData);
                } else {
                    this.status = RESOURCE.Failure(responseData.message, responseData);
                }
                this.message = this.status.message;
                window.clearInterval(this.interval);
                this.setView(this.CHECKOUT_VIEW);
            })
            .then(() => {
                const isSuccessAlready = RESOURCE.Success.is(this.status);
                const isNotPending = isSuccessAlready && this.status.data['transaction_status'] !== 'Pending';

                if (isNotPending) {
                    window.clearInterval(this.interval);
                    this.isBusy = false;
                }
            });
    }

    @computed get cartServices() {
        return new CartServices(this.itemsInCart);
    }

    @action getOrderSummary() {
        const requestBody = this.getRequestPayload();

        this.isBusy = true;
        services.api
            .GetOrderSummary(requestBody)
            .then((responseData) => {
                this.orderSummary = responseData;
                this.onOrderSummaryLoaded$.push(responseData);
            })
            .catch((responseData) => {
                this.message = responseData.message;
            })
            .then(() => {
                this.isBusy = false;
            });
    }

    @action placeOrderWithBoost = () => {
        this.setView(this.PAYMENT_IN_PROGRESS_VIEW);
    };

    @action placeOrderWithCard(deviceData) {
        this.setView(this.PAYMENT_IN_PROGRESS_VIEW);
        const requestBody = this.getRequestPayload();
        this.isBusy = true;
        services.api
            .PlaceMealOrder(requestBody, deviceData)
            .then(async (responseData) => {
                this.orderId = responseData.group_id;
                this.finalOrderSummary = responseData;
                console.log(responseData, 'Response data');
                if (responseData.client_secret) {
                    const stripe = await stripePromise;
                    stripe
                        .handleNextAction({
                            clientSecret: responseData.client_secret,
                        })
                        .then((result) => {
                            console.log(result, 'results');
                            if (result.error) {
                                services.api
                                    .SendPaymentIntentID(
                                        { payment_intent_id: result.error.payment_intent.id },
                                        deviceData
                                    )
                                    .then(async (responseData) => {
                                        this.setView(this.CHECKOUT_VIEW);
                                        this.message = result.error.message;
                                    });
                            } else {
                                this.paymentIntentId = result.payment_intent_id;
                                services.api
                                    .SendPaymentIntentID({ payment_intent_id: result.paymentIntent.id }, deviceData)
                                    .then(async (responseData) => {
                                        this.view = this.CONFIRM_VIEW;
                                        services.api.UserCart.clear();
                                    });
                            }
                            // Handle result.error or result.paymentIntent
                        });
                } else {
                    this.view = this.CONFIRM_VIEW;
                    services.api.UserCart.clear();
                }
            })

            .then(::this.sendTrackingEvents)

            .catch((responseData) => {
                this.setView(this.CHECKOUT_VIEW);
                this.message = responseData.message;
                this.onPlacingOrderFailed$.push();
            })
            .then(() => {
                this.isBusy = false;
            });
    }

    @action placeOrderWithOnlineBanking = () => {
        const params = qs.parse(window.location.search);
        const fromBoostApp = params.platform === 'boost' || Cookies.get('boost_user');

        if (this.paymentMethod.id !== 'boost' && !window.matchMedia(SMALL_SCREEN_MEDIA).matches) {
            this.setView(this.PAYMENT_IN_PROGRESS_VIEW);
            this.isBusy = true;
        }

        const requestBody = this.getRequestPayload();

        services.api
            .PlaceMealOrder(requestBody)
            .then((responseData) => {
                this.orderId = responseData.group_id;
                if (
                    responseData.payment_method === 'Boost' &&
                    responseData.bill_deeplink &&
                    window.matchMedia(SMALL_SCREEN_MEDIA).matches
                ) {
                    if (fromBoostApp) {
                        window.location = responseData.bill_deeplink;
                    } else {
                        this.boostDeepLink = responseData.bill_deeplink;
                        this.boostOrderId = responseData.list.find((order) => order.order_id === this.orderId).id;
                        this.openBoostPopup = true;
                    }
                } else if (responseData.bill_url && responseData.payment_method === 'Boost') {
                    if (fromBoostApp) {
                        window.location = responseData.bill_url;
                    } else {
                        window.open(responseData.bill_url, '_blank');
                    }
                } else if (responseData.bill_url) {
                    window.location = responseData.bill_url;
                }
                // below is deprecated, orderprocessing happens on billplz and order processing is stored in menuBillPlzStore
                else if (responseData.message === 'Successfully Placed!') {
                    this.finalOrderSummary = responseData;
                    this.view = this.CONFIRM_VIEW;
                    this.sendTrackingEvents();
                    services.api.UserCart.clear();
                } else {
                    this.setView(this.CHECKOUT_VIEW);
                    this.message = responseData.message;
                }
            })
            .then((this.isBusy = false))
            .catch((responseData) => {
                this.setView(this.CHECKOUT_VIEW);
                this.message = responseData.message;
                this.onPlacingOrderFailed$.push();
            });
    };

    @action cancelOrder = () => {
        services.api
            .CancelOrder({ id: this.boostOrderId })
            .then((response) => {
                this.message = response.message;
                clevertap.event.push('Cancel Order');
                clearInterval(this.interval);
            })
            .catch((response) => {
                this.message = response.message;
            });
    };

    @action setUser(user) {
        this.user = user;
        this.onUserLoaded$.push();
    }

    @action setPaymentMethod(paymentMethod) {
        this.paymentMethod = paymentMethod;
        this.onPaymentMethodsLoaded$.push(paymentMethod);
        if (paymentMethod) {
            if (paymentMethod.payment_method_type === 'Card') {
                const cardType = getCardTypeText(paymentMethod);
                this.sendPaymentAddedTracking(cardType);
            } else if (paymentMethod.payment_method_type === 'Cash') {
                this.sendPaymentAddedTracking('COD');
            } else if (paymentMethod.payment_method_type === 'Boost') {
                this.sendPaymentAddedTracking('Boost');
            } else {
                this.sendPaymentAddedTracking(paymentMethod.payment_method_type);
            }
        }
    }

    sendPaymentAddedTracking(method) {
        clevertap.event.push('Payment method added', {
            'payment method': method,
        });

        window.dataLayer.push({ event: 'AddPaymentInfo' });
    }

    selectOtherMealTime = (mealTime) => {
        return mealTime === 'Lunch' ? 'Dinner' : 'Lunch';
    };

    sendTrackingEvents() {
        const purchaseEventName = services.getParam('purchaseEventName');
        const newCustomerTag = this.user.has_ordered_before ? 'existing' : 'new';

        clearReferralItemsFromLocalStorage();
        removeActiveTimeslotObj();
        removeRewardToApply();

        clevertap.profile.push({
            Site: {
                'Last Order Number': '#' + this.finalOrderSummary.group_id,
            },
        });

        if (this.finalOrderSummary.first_order === true || this.finalOrderSummary.first_purchase_pop_meals === true) {
            window.dataLayer.push({
                event: 'First Purchase Pop Meals',
            });
        }

        window.dataLayer.push({
            event: 'transactionComplete',
            ecommerce: {
                purchase: {
                    actionField: {
                        id: this.finalOrderSummary.group_id,
                        revenue: this.orderSummary.sub_total,
                        coupon: this.discountCode,
                    },
                    products: this.cartServices.getGTMProductList(),
                },
            },
        });

        window.dataLayer.push({
            event: 'Purchase',
            value: currency.to2digits(this.orderSummary.sub_total),
            currency: services.getParam('currencyCode'),
            content_name: this.cartServices.getGTMProductList(),
            content_type: 'product',
            content_ids: this.cartServices.getGTMProductList().map((item) => item.id),
            contents: this.cartServices.getGTMProductList().map((item) => ({
                id: item.id,
                quantity: item.quantity,
                item_price: item.price,
            })),
            order_id: this.finalOrderSummary.group_id,
            customer_type: newCustomerTag,
        });

        window.dataLayer.push({
            event: this.finalOrderSummary['first_order'] ? 'first_order' : 'reorder',
            valueToSum: this.finalOrderSummary['gross_total'],
        });
    }

    getRequestPayload() {
        const params = qs.parse(window.location.search);
        const fromBoostApp = params.platform === 'boost' || Cookies.get('boost_user');

        return {
            customer_id: this.user.id,
            card_id: this.paymentMethod ? this.paymentMethod.id : 'cod-temp',
            payment_method: this.paymentMethod ? this.paymentMethod['payment_method_type'] : 'Cash',
            discount_code: this.discountCode,
            orders: cartStore.getDeliveriesForRequest(this.cart()),
            fpx_redirect_url: services.getParam('fpxRedirectUrlMenu'),
            fpx_gateway: this.paymentMethod ? this.paymentMethod['fpx_gateway'] : null,
            from_boost_app: fromBoostApp,
            disable_deeplink: true,
        };
    }

    setView(view) {
        this.view = view;
    }

    @action sendStripeReceipt = (request) => {
        this.isLoading = true;
        services.api
            .SendStripeReceipt(request)
            .then(() => {
                this.handleCreateSelectSubscription();
            })
            .catch((e) => {
                this.message = e.message;
                this.isLoading = false;
            });
    };
}

const store = new MenuCheckoutStore();
export default store;
