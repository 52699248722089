import React from 'react';

import { inject, observer } from 'mobx-react';
import intl from 'react-intl-universal';
import QRCode from 'qrcode';

const base = 'terminal-payment-option';
@inject('terminalCartStore')
@inject('terminalMenuStore')
@inject('menuCheckoutStore')
@observer
export default class extends React.Component {
    state = {
        receiptSent: false,
        showQR: false
    };

    componentDidMount() {
        const { terminalMenuStore } = this.props;
        setTimeout(() => {
            if (!terminalMenuStore.askForName || terminalMenuStore.isSkipClicked) {
                terminalMenuStore.showingPopup =
                    terminalMenuStore.POPUP_CONFIRM_ORDER;
            } else {
                terminalMenuStore.showLuckyWheelScreen = true;
            }
        }, 5000); //5 seconds
    }

    handleGetReceiptClicked = () => {
        const { terminalMenuStore, menuCheckoutStore } = this.props;

        //If order placed via card
        if (menuCheckoutStore.paymentIntentId) {
            const email = terminalMenuStore.customerEmail;
            menuCheckoutStore.sendStripeReceipt({
                payment_intent_id: menuCheckoutStore.paymentIntentId,
                email: email,
                order_id: menuCheckoutStore.orderId
            });
            if (email) {
                this.setState({receiptSent : true})
            } else {
                this.setState({showQR : true})
                this.renderQRCodeTxt();
            }
        }
    };

    renderQRCodeTxt = () => {
        const { menuCheckoutStore } = this.props;
        let text = `https://wa.me/601154193392?text=Please%20send%20me%20the%20receipt%20for%20order%20${menuCheckoutStore.orderId}`;
        setTimeout(() => {
            let canvas = document.getElementById('qrcode');
            if (canvas) {
                QRCode.toCanvas(canvas, text, {
                    width: 200,
                    height: 200,
                });
            }
        }, 500);
    };

    handleClosePopup = () => {
        const { terminalMenuStore } = this.props;
        if (!terminalMenuStore.askForName || terminalMenuStore.isSkipClicked) {
            terminalMenuStore.showingPopup =
                terminalMenuStore.POPUP_CONFIRM_ORDER;
        } else {
            terminalMenuStore.showLuckyWheelScreen = true;
        }
    }

    render() {
        const { terminalMenuStore } = this.props;
        const {receiptSent, showQR} = this.state;
        return (
            <div className={base}>
                { terminalMenuStore.paymentType == 'Kiosk_Ewallet' ? (<div className={`${base}__title`}>
                    <p>Scan your QR code to pay</p>
                </div>) : null }
                
                <div style={{ marginTop: '50px' }}>
                    <img src="/greenTick.png" />
                </div>

                {!showQR && (
                    <div
                        style={{
                            fontSize: '64px',
                            fontWeight: 600,
                            maxWidth: '384px',
                            margin: '35px auto',
                        }}
                    >
                        {intl.get('terminal.qrPopup.payment_successful')}
                    </div>
                )}
                
                {
                    !receiptSent && !showQR && (
                        <div
                            style={{
                                margin: '15px auto 40px auto',
                                backgroundColor: '#FEC700',
                                width: '283px',
                                height: '50px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            onClick={this.handleGetReceiptClicked}
                        >
                            <p style={{
                                fontSize: '24px',
                                fontWeight: '600',
                            }}>
                                    NEED A RECEIPT?
                            </p>
                        </div>
                    )
                }
                {
                    receiptSent && (
                        <div
                            style={{
                                margin: '15px auto 40px auto',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <p style={{
                                fontSize: '24px',
                                fontWeight: '600',
                            }}>
                                    Receipt sent to your email address.
                            </p>
                        </div>
                    )
                }
                {
                    showQR && (
                        <div
                            style={{
                                margin: '15px auto 40px auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <div style={{
                                fontSize: '36px',
                                fontWeight: '600',
                            }}>
                                    Scan this QR code to get your receipt
                            </div>
                            <canvas id="qrcode" />

                            <div
                                style={{
                                    margin: '24px auto 40px auto',
                                    backgroundColor: '#FEC700',
                                    width: '283px',
                                    height: '50px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                                onClick={this.handleClosePopup}
                            >
                                <p style={{
                                    fontSize: '24px',
                                    fontWeight: '600',
                                }}>
                                        Done
                                </p>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}
